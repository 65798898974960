@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

body {
  background: #eaeaea;
}

.wrapper .header {
  background: #29a5d8;
}

.wrapper .title {
  padding: 50px 0;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: #fff;
}

.wrapper .search_box {
  max-width: 1000px;
  background: #29a5d8;
  margin: 0 auto;
  padding: 25px 0 50px;
  border-radius: 3px;
}

.wrapper .search_box input {
  border: 0;
  border-bottom: 2px solid #e5edfa;
  width: 40%;
  outline: none;
  padding: 10px;
  background: transparent;
  color: #fff;
  font-size: 16px;
  margin-right: 20px;
}

::placeholder {
  color: #e5edfa;
}

.wrapper .search_box input:focus {
  border-bottom: 2px solid #fff;
}

.table_wrap {
  width: 1000px;
  margin: 50px auto 0;
}

.table_wrap ul li .item {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px 0;
  height: 50px;
}

/* .table_wrap ul li .item .name,
.table_wrap ul li .item .phone {
  width: 20%;
  padding: 0 15px;
}

.table_wrap ul li .item .status {
  width: 15%;
  padding: 0 15px;
}

.table_wrap ul li .item .issue {
  width: 45%;
  padding: 0 15px;
}

.table_wrap ul li .item .issue span,
.table_wrap ul li .item .name span {
  width: 90%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* .table_header ul li .item {
  border-bottom: 2px solid #eaeaea;
  font-weight: 600;
}

.table_body {
  height: 300px;
  overflow: auto;
}

.table_body ul li .item {
  border-bottom: 1px solid #efefef;
}

.table_body ul li .item .status span {
  padding: 5px 20px;
  border-radius: 25px;
  max-width: 115px;
  display: inline-block;
}

.table_body ul li .item .open {
  background: #e5edfa;
  color: #5a8ee4;
}

.table_body ul li .item .fixed {
  background: #cff1f0;
  color: #0dbeb6;
}

.table_body ul li .item .closed {
  background: #fedfe5;
  color: #ff89a0;
}

.table_body ul li .item .hold {
  background: #fff0db;
  color: #f59d39;
}

.table_body ul li .item .reopened {
  background: #d6f2ff;
  color: #29a5d8;
}

.table_body ul li .item .canceled {
  background: #ffdbd6;
  color: #e87060;
} */



.TableData {
    font-family: sans-serif;
    text-align: center;
}

.ReactVirtualized__Table__rowColumn {
  width: 100%;
  height: 100%;
  /* border-bottom: 1px solid rgb(0, 0, 0); */
  display: flex;
  /* align-items: center; */
  /* padding: 0 0.5rem; */
  box-sizing: border-box;
}

.TableData {
  border: 2px solid gray;
} 

.search-input{
  height: 125px;
}

/* .ReactVirtualized__Table__headerRow {
  border-bottom: 2px solid gray;
} */

/* .ReactVirtualized__Table__row {
  height: 40px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 821px;
  overflow: hidden;
  padding-right: 0px;
  border-bottom: 1px solid gray;
} */

.ReactVirtualized__Table__headerRow {
  font-family: "Montserrat", sans-serif !important;
  background-color: white;
  height: 50px;
  font-weight: 600 !important;
}

 .ReactVirtualized__Table__Grid {
    box-sizing: border-box;
    direction: ltr;
    position: relative;
    width: 1000px;
    will-change: transform;
    overflow: hidden auto;
    height: 232px;
    margin-top: 15px;
    background-color: white;
    font-family: "Montserrat", sans-serif !important;
  }

  .ReactVirtualized__Table__row {
    border-bottom: 1px solid #efefef;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    padding: 15px 0;
  }

  /* //     margin-right: -200px; */

  div.ReactVirtualized__Table__row :nth-child(4) {
    margin-right: -180px !important;
  }

  div.ReactVirtualized__Table__row :nth-child(4) .buy {
    padding: 5px 20px;
    border-radius: 25px;
    max-width: 115px;
    display: inline-block;
    background: #cff1f0;
    color: #0dbeb6;
    height: 30px;
  }

  div.ReactVirtualized__Table__row :nth-child(4) .sell {
    padding: 5px 20px;
    border-radius: 25px;
    max-width: 115px;
    display: inline-block;
    background: red;
    color:  white;
    height: 30px;
  } 

  .ReactVirtualized__Table__headerColumn:nth-child(1) {
    width: 30%;
  }

  .ReactVirtualized__Table__headerColumn:nth-child(2) {
    width: 25%;
  }

  .ReactVirtualized__Table__headerColumn:nth-child(3) {
    width: 30%;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    font-size: 12px !important;
  }

  .disclaimer-content {
    font-weight: 100,
    /* font-size: '7px', */
  }

  .disclaimer-text-grid {
    font-size: 7px;
    font-weight: 100;
  }



  @media screen and (max-width: 800px) {

    .wrapper .search_box input {
      border: 0;
      border-bottom: 2px solid #e5edfa;
      width: 40%;
      outline: none;
      padding: 10px;
      background: transparent;
      color: #fff;
      font-size: 16px;
    }

    .disclaimer-text-grid {
      font-size: 5px;
      font-weight: 100;
    }

    .ReactVirtualized__Table__headerColumn:nth-child(1) {
      width: 30%;
    }
  
    .ReactVirtualized__Table__headerColumn:nth-child(2) {
      width: 40%;
    }
  
    .ReactVirtualized__Table__headerColumn:nth-child(3) {
      width: 30%;
    }

    .disclaimer-content {
      font-weight: 100,
      /* font-size: '3px', */
    }

    .stock-row-header {
      margin-left: 50px;
    }

    .stock-name-column {
      margin-right: 30px;;
    }

    .market-title-legend {
      visibility: hidden;
      clear: both;
      float: left;
      display: none !important;
    }

    .trade-title-legend {
      visibility: hidden;
      clear: both;
      float: left;
      display: none !important;
    }

    .exchange-row {
      visibility: hidden;
      clear: both;
      float: left;
      display: none;
    }

    .exchange-row-header {
      visibility: hidden;
      clear: both;
      float: left;
      display: none;
    }

    .about {
      visibility: hidden;
      clear: both;
      float: left;
      display: none;
    }

    .scrip-table-container {
      .scrip-table {
        height: 200vh !important;
      }
    }

    .search-input{
      height: 90px;
    }

    div.ReactVirtualized__Table__row :nth-child(4) {
      margin-right: 0px !important;
    }

    #search_input {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }

    .backtest-notes {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }



    .ReactVirtualized__Grid__innerScrollContainer {
      font-size: 10px !important;
    }

    .banner-heading {
      font-size: 14px !important;
    }

    .banner-appname {
      font-size: 22px !important;
    }

    .footer-banner {
      font-size: 10px !important;
    }

    .market-selector {
      width: 40%;
    }

    .direction-selector {
      width: 40%;
      margin-left: 10%;
    }
  }

  .market-selector {
    /* background: #29a5d8;
    color: white; */
    padding: 13px;
    margin-left: 20px;
    border-bottom: 1px solid #e5edfa;
    outline: 0.5px solid white;
    margin-right: 20px;
  }

  .direction-selector {
    /* background: #29a5d8;
    color: white; */
    padding: 13px;
    margin-left: 20px;
    border-bottom: 1px solid #e5edfa;
    outline: 0.5px solid white;
  }

  .backtest-notes {
    font-family: font-family Montserrat, sans-serif;
    color: white;
    font-size: 11px;
    margin-top: 8px;
    margin-left: 5px;
  }

  .btn_twitter {
    position: relative;
    height: 20px;
    box-sizing: border-box;
    padding: 1px 12px 1px 12px;
    background-color: #000;
    color: #fff;
    border-radius: 9999px;
    font-weight: 500;
    cursor: pointer;
  }